import { fetchJobs, fetchProjects } from '../../api/CEM/cem.api';
import { AppDispatch } from '../../app/store';
import { setCemDataLoadedFlag, setJobs, setProjects } from '../Slices/Cem.slice';
import { Job } from '../Types/Job.types';
import { Project } from '../Types/Project.types';

export async function FetchCemEngagements(dispatch: AppDispatch, clientId: number) {
    dispatch(setCemDataLoadedFlag(false));
    const clientJobs = await fetchJobs(clientId);
    const clientProjects = await fetchProjects(clientId);
    const projects = JSON.parse(clientProjects.Data.toString()).results as Project[];
    const jobs = JSON.parse(clientJobs.Data.toString()).results as Job[];
    dispatch(setJobs(jobs));
    dispatch(setProjects(projects));
    dispatch(setCemDataLoadedFlag(true));
}