import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Guid } from 'guid-typescript';
import { UserList } from '../../component/status-widget/types/UserList.types';
import { Client } from '../Types/Client.types';
import { AppDispatch, RootState } from '../../app/store';
import { fetchClients } from '../../api/CEM/cem.api';
import { Project } from '../Types/Project.types';
import { Job } from '../Types/Job.types';
import { showErrorSnackbar } from '../../component/snackbar/Snackbar.slice';
import { Messages } from '../../Constants/Messages';
import { CemResponse } from '../../api/CEM/CemClientResponse.types';
import { Entity } from '../../pages/home/entity/Entity';
import { CemData } from '../Types/Cem.types';
import { FetchCemEngagements } from '../Utils/CemEngagementFetcher';
import { StateStoring } from 'devextreme-react/data-grid';

interface State {
	clients: Client[];
	selectedClient: Client;
	projects: Project[];
	selectedProject: Project;
	jobs: Job[];
	selectedJob: Job;
	userList: UserList[];
	entityListForClients: Entity[]
	dataLoaded: boolean;
	fetchedClientIds: number[]
}

const initialState: State = {
	clients: [],
	selectedClient: {} as Client,
	projects: [],
	selectedProject: {} as Project,
	jobs: [],
	selectedJob: {} as Job,
	userList: [],
	entityListForClients: [],
	dataLoaded: false,
	fetchedClientIds: []
};

export interface EngagementAttribute {
	mdmClientId: number;
	id: Guid;
}

export const fetchClientData = createAsyncThunk<
	void,
	string[],
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('job/fetchClientData', async (presenterClientIds, thunkAPI) => {
	fetchClients()
		.then(async function (result) {
			const state = thunkAPI.getState();
			if (result.Success) {
				const clientData = JSON.parse(result.Data.toString()) as CemResponse;
				let clients: Client[];
				if (presenterClientIds.length > 0 && clientData) {
					clients = (clientData.results as Client[])
						.filter(x => presenterClientIds.includes(x.mdmClientId.toString()))
				} else {
					clients = clientData.results as Client[];
				}
				thunkAPI.dispatch(setClients(clients));
				if (Object.keys(state.cem.selectedClient).length === 0) {
					thunkAPI.dispatch(clientSelected(clients[0]));
				}
			} else {
				showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH)
			}
		})
});

export const fetchCemData = createAsyncThunk<
	void,
	number,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('job/fetchCemData', async (clientId, thunkAPI) => {

	await FetchCemEngagements(thunkAPI.dispatch, clientId);
	{
		showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH)
	}
});

const cemSlice = createSlice({
	name: 'cem',
	initialState,
	reducers: {
		setClients: (state, action: PayloadAction<Client[]>) => {
			state.clients = action.payload;
		},
		clientSelected: (state, action: PayloadAction<Client | undefined>) => {
			if (action.payload) {
				state.selectedClient = action.payload;
			}
		},
		setProjects: (state, action: PayloadAction<Project[]>) => {
			state.projects = action.payload;
		},
		projectSelected: (state, action: PayloadAction<Project | undefined>) => {
			if (action.payload) {
				state.selectedProject = action.payload;
			}
		},
		setJobs: (state, action: PayloadAction<Job[]>) => {
			state.jobs = action.payload;
		},
		jobSelected: (state, action: PayloadAction<Job | undefined>) => {
			if (action.payload) {
				state.selectedJob = action.payload;
			}
		},
		setUserList: (state, action: PayloadAction<UserList[]>) => {
			state.userList = action.payload;
		},
		setEntityListForClients: (state, action: PayloadAction<Entity[]>) => {
			return { ...state, entityListForClients: action.payload };
		},
		setCemDataLoadedFlag: (state, action: PayloadAction<boolean>) => {
			state.dataLoaded = action.payload;
		},
		setFetchedClientIds: (state, action: PayloadAction<number[]>) => {
			return { ...state, fetchedClientIds: action.payload };
		},
		clearSelections: (state) => {
			state.selectedProject = initialState.selectedProject;
			state.selectedJob = initialState.selectedJob;
		},
		clearAllCemData: () => {
			return { ...initialState };
		},
		refreshJobAndProjectList: (state) => {
			state.jobs = initialState.jobs;
			state.projects = initialState.projects;
		}
	},
});

export const {
	clientSelected,
	jobSelected,
	setUserList,
	projectSelected,
	clearSelections,
	setEntityListForClients,
	setCemDataLoadedFlag,
	clearAllCemData,
	setJobs,
	setProjects,
	setFetchedClientIds,
	refreshJobAndProjectList,
	setClients
} = cemSlice.actions;

export default cemSlice.reducer;
