import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { fetchAnalysisSettings } from '../../api/Settings/CalculationSettings.api';
import { ResponseData } from '../../api/ApiFetch/ApiResponse';
import { AnalysisSettingsResponse } from './Settings.types';

interface State {
	analysisDetails: AnalysisSettingsResponse;
}

const initialState: State = {
	analysisDetails: {} as AnalysisSettingsResponse,
};

export const fetchAnalysisSettingsData = createAsyncThunk<
	void,
	void,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('dataset/fetchAnalysisSettings', async (_: void, thunkAPI) => {
	thunkAPI.dispatch(setAnalysisToInitial());
	fetchAnalysisSettings().then(function (result: ResponseData) {
		if (result.Success) {
			let data = JSON.parse(result.Data.toString()) as AnalysisSettingsResponse;
			data.analysis = data.analysis.sort((a, b) => a.analysisGroup - b.analysisGroup);
			thunkAPI.dispatch(setAnalysisDetails(data));
		}
	});
});

const analysisSlice = createSlice({
	name: 'analysis',
	initialState,
	reducers: {
		setAnalysisDetails(state, action: PayloadAction<AnalysisSettingsResponse>): any {
			return { ...state, analysisDetails: action.payload };
		},
		setAnalysisToInitial(): State {
			return { ...initialState };
		},
	},
});

export const { setAnalysisDetails, setAnalysisToInitial } = analysisSlice.actions;

export default analysisSlice.reducer;
