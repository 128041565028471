import { Guid } from 'guid-typescript';
import {
    AnalysisGroup, AnalysisSetting, AnalysisSettingName,
    Bifurcation,
    CalculationBaseGroup, Character,
    ClientType, ConstructiveSales, ForcedConstructiveSales, 
    Hedging, HedgingType, IRC475, MTM, ReliefMethodOptions,
    Reporting
} from '../../component/Settings/Settings.types';

export enum HierarchyType {
    Client = 1,
    Project = 2,
    Entity = 3,
}

export interface VolumeMultiplierResponse {
    id: Guid;
    minimumVolume: number;
    maximumVolume?: string;
    multiplier: number;
    modifiedBy: string;
    modifiedDate: Date;
    minimumVolumeValue: string;
}

export interface VolumeMultiplierInsertOrUpdate {
    id?: Guid;
    minimumVolume?: number;
    maximumVolume?: number;
    multiplier?: number;
}

export interface PricingOverrideInsertOrUpdate {
    id?: string;
    hierarchyType?: HierarchyType;
    clientId?: string;
    projectId?: string;
    entityId?: string;
    amount?: number;
}

export interface PricingOverrideResponse {
    id: string;
    hierarchyType: HierarchyType;
    hierarchyTypeName: string;
    clientId: string;
    projectId: string;
    entityId: string;
    amount: number;
    amountFormatted: string;
    modifiedBy: string;
    modifiedDate: Date;
}

export interface PricingInsertOrUpdate {
    complexity?: number;
    internal?: number;
    external?: number;
    id?: Guid;
}

export interface PricingResponse {
    id: Guid;
    complexity: number;
    internal: number;
    internalFormatted: string;
    external: number;
    externalFormatted: string;
    modifiedBy: string;
    modifiedDate: Date;
}

export interface CalculationSubSettingsFactorModel {
    id?: Guid;
    found: number;
    notFound: number;
    analysisSubType: number;
    analysisSubTypeValue: string;
    analysisGroup: number;
}

export interface CalculationSubSettingsFactorProps {
    calculationSubSettingsFactorInsertOrUpdate: CalculationSubSettingsFactorInsertOrUpdate;
    isEdit: boolean;
    onClose: () => void;
    insert: (data: CalculationSubSettingsFactorModel) => Promise<void>;
    update: (data: CalculationSubSettingsFactorModel) => Promise<void>;
}

export interface VolumeMultiplierProps {
    volumeMultiplierInsertOrUpdate: VolumeMultiplierInsertOrUpdate;
    isEdit: boolean;
    onClose: () => void;
    insert: (data: VolumeMultiplierInsertOrUpdate) => Promise<void>;
    update: (data: VolumeMultiplierInsertOrUpdate) => Promise<void>;
}

export interface PricingProps {
    pricingInsertOrUpdate: PricingInsertOrUpdate;
    isEdit: boolean;
    onClose: () => void;
    insert: (data: PricingInsertOrUpdate) => Promise<void>;
    update: (data: PricingInsertOrUpdate) => Promise<void>;
}

export interface PricingOverrideProps {
    pricingOverrideInsertOrUpdate: PricingOverrideInsertOrUpdate;
    isEdit: boolean;
    onClose: () => void;
    insert: (data: PricingOverrideInsertOrUpdate) => Promise<void>;
    update: (data: PricingOverrideInsertOrUpdate) => Promise<void>;
}

export interface CalculationSettingsFactorProps {
    calculationSettingsFactorInsertOrUpdate: CalculationSettingsFactorInsertOrUpdate;
    isEdit: boolean;
    onClose: () => void;
    insert: (data: CalculationSettingsFactorInsertOrUpdate) => Promise<void>;
    update: (data: CalculationSettingsFactorInsertOrUpdate) => Promise<void>;
}

export interface CalculationSubSettingsFactorResponse {
    id: Guid;
    found: number;
    notFound: number;
    analysisSubType: number;
    analysisSubTypeValue: string;
    analysisGroup: AnalysisGroup;
    analysisGroupName: string;
    analysisSubTypeName: string;
    modifiedBy: string;
    modifiedDate: Date;
}

export interface CalculationSubSettingsFactorInsertOrUpdate {
    id?: Guid;
    found?: number;
    notFound?: number;
    analysisSubType?: string;
    analysisSubTypeValue?: string;
    analysisGroup?: string;
}

export interface CalculationSettingsFactorModel {
    found: number;
    notFound: number;
    analysisGroup: AnalysisGroup;
}

export interface CalculationSettingsFactorResponse {
    id: Guid;
    found: number;
    notFound: number;
    analysisGroup: AnalysisGroup;
    analysisGroupName: string;
    modifiedBy: string;
    modifiedDate: Date;
}

export interface CalculationSettingsFactorInsertOrUpdate {
    id?: Guid;
    found?: number;
    notFound?: number;
    analysisGroup?: AnalysisGroup;
}

export class GenericDropdownValue {
    name: string;
    id: string;

    constructor(name: string, id: string) {
        this.id = id;
        this.name = name;
    }
}

export interface GenericDropdown {
    name: string;
    id: string;
}

export const hierarchyTypeData = [
    {
        hierarchyType: 0,
        name: 'Client'
    },
    {
        hierarchyType: 1,
        name: 'Project'
    },
    {
        hierarchyType: 2,
        name: 'Entity'
    }
];

export const reportingValues: GenericDropdown[] = [
    {
        name: 'Annual',
        id: Reporting.Annual.toString()
    },
    {
        name: 'Daily',
        id: Reporting.Daily.toString()
    },
    {
        name: 'Monthly',
        id: Reporting.Monthly.toString()
    }
];

export const clientTypeValues: GenericDropdown[] = [
    {
        name: 'Investor',
        id: ClientType.Investor.toString()
    },
    {
        name: 'Trader',
        id: ClientType.Trader.toString()
    }
];

export const bifurcationValues: GenericDropdown[] = [
    {
        name: 'Upload',
        id: Bifurcation.Upload.toString()
    },
    {
        name: 'Disposition',
        id: Bifurcation.Disposition.toString()
    }
];

export const forcedConstructiveSalesValues: GenericDropdown[] = [
    {
        name: ForcedConstructiveSales.Yes,
        id: ForcedConstructiveSales.Yes
    },
    {
        name: ForcedConstructiveSales.No,
        id: ForcedConstructiveSales.No
    }
];

export const genericValues: GenericDropdown[] = [
    {
        name: ReliefMethodOptions.FIFO,
        id: ReliefMethodOptions.FIFO
    },
    {
        name: ReliefMethodOptions.LIFO,
        id: ReliefMethodOptions.LIFO
    },
    {
        name: ReliefMethodOptions.MAX_GAIN,
        id: ReliefMethodOptions.MAX_GAIN
    },
    {
        name: ReliefMethodOptions.MIN_GAIN,
        id: ReliefMethodOptions.MIN_GAIN
    }
];

export const iRC475Values: GenericDropdown[] = [
    {
        name: IRC475.FourSevenFiveF1,
        id: IRC475.FourSevenFiveF1
    },
    {
        name: IRC475.FourSevenFiveF2,
        id: IRC475.FourSevenFiveF2
    }
];

export const characterValues: GenericDropdown[] = [
    {
        name: Character.IRC1256,
        id: Character.IRC1256
    },
    {
        name: Character.SixtyFortySplit,
        id: Character.SixtyFortySplit
    }
];

export const booleanValues: GenericDropdown[] = [
    {
        name: 'True',
        id: 'True'
    },
    {
        name: 'False',
        id: 'False'
    }
];

export const hedgingValues: GenericDropdown[] = [
    {
        name: HedgingType.OneToAll,
        id: HedgingType.OneToAll
    },
    {
        name: HedgingType.OneToOne,
        id: HedgingType.OneToOne
    }
];

export const hierarchyTypeDropDown: GenericDropdown[] = [
    {
        name: 'Client',
        id: HierarchyType.Client.toString()
    },
    {
        name: 'Project',
        id: HierarchyType.Project.toString()
    },
    {
        name: 'Entity',
        id: HierarchyType.Entity.toString()
    }
];

export const subSettingDisplay = new Map<string, GenericDropdown[]>([
    [AnalysisGroup.Calculation + '_' + CalculationBaseGroup.Reporting, reportingValues],
    [AnalysisGroup.Calculation + '_' + CalculationBaseGroup.ClientType, clientTypeValues],
    [AnalysisGroup.Calculation + '_' + CalculationBaseGroup.Bifurcation, bifurcationValues],
    [(AnalysisGroup.ConstructiveSales * 100) + '_' + ConstructiveSales.ForcedConstructiveSales, forcedConstructiveSalesValues],
    [(AnalysisGroup.ConstructiveSales * 100) + '_' + ConstructiveSales.ReliefMethodology, genericValues],
    [(AnalysisGroup.IRC475 * 100 + '_1'), iRC475Values],
    [(AnalysisGroup.Character * 100 + '_1'), characterValues],
    [(AnalysisGroup.Dividend * 100 + '_1'), booleanValues],
    [(AnalysisGroup.WashSales * 100 + '_1'), booleanValues],
    [(AnalysisGroup.MTM * 100 + '_' + MTM.PFIC), booleanValues],
    [(AnalysisGroup.MTM * 100 + '_' + MTM.SWAP), booleanValues],
    [(AnalysisGroup.MTM * 100 + '_' + MTM._1256), booleanValues],
    [(AnalysisGroup.MTM * 100 + '_' + MTM._475f1), booleanValues],
    [(AnalysisGroup.MTM * 100 + '_' + MTM._475f2), booleanValues],
    [AnalysisGroup.Hedging * 100 + '_' + Hedging.HedgingType, hedgingValues],
    [AnalysisGroup.Hedging * 100 + '_' + Hedging.OffsetRelief, genericValues],
    [AnalysisGroup.Hedging * 100 + '_' + Hedging.RealizedRelief, genericValues],
]);

export const calculationSubTypeNames = new Map<number, string>([
    [CalculationBaseGroup.Bifurcation, '988 Bifurcation'],
    [CalculationBaseGroup.ClientType, 'Fund Type'],
    [CalculationBaseGroup.Reporting, 'Reporting'],
]);

export const hierarchyTypeNames = new Map<number, string>([
    [HierarchyType.Client, 'Client'],
    [HierarchyType.Entity, 'Entity'],
    [HierarchyType.Project, 'Project'],
]);

export const GetHierarchyTypeNames = (hierarchyType: HierarchyType): string => {
    return hierarchyTypeNames.get(hierarchyType) as string;
};

export const GetAnalysisSubTypes = (analysisGroup: Number, analysisSubType: number, analysisSetting: AnalysisSetting[]) => {
    if (analysisGroup === AnalysisGroup.Calculation) {
        return calculationSubTypeNames.get(analysisSubType) as string;
    }
    const filter = analysisSetting.find(v => v.analysisGroup === Number(analysisGroup));
    if (filter !== undefined) {
        switch (analysisGroup) {
            case AnalysisGroup.Hedging:
            case AnalysisGroup.ConstructiveSales:
            case AnalysisGroup.Dividend:
            case AnalysisGroup.MTM:
                const analysisSettingNames = JSON.parse(filter.name) as AnalysisSettingName[];
                const analysisSettingItem = analysisSettingNames.find(x => x.type === analysisSubType);
                return analysisSettingItem ? analysisSettingItem.name : '';
            case AnalysisGroup.WashSales:
            case AnalysisGroup.IRC475:
            case AnalysisGroup.Character:
                return filter.name;
        }
    }
    return '';
};
