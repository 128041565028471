import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { showErrorSnackbar } from '../../component/snackbar/Snackbar.slice';
import { AppDispatch } from '../../app/store';
import {
	CalculationFailureMessage,
	CalculationInitiationMessage,
	CalculationSuccessMessage,
	ClientReferenceUploadMessage,
	ImportMessage,
	SettingsNotification,
	StatusWidgetMessage,
} from '../Types/Notifications.types';
import { setIsSignalRConnected, signalRConnected } from '../Slices/Notifications.slice';
import { envProperties } from '../Utils/EnvProperties';
import { getAccessToken } from '@rsmus/react-auth';
import { SignalrHubMethods } from '../../Constants/SignalrHubMethods';
import {
	signalRKeepAliveIntervalInMilliseconds,
	signalRServerTimeoutInMilliseconds,
	signalRTimeout,
} from '../Utils/constants';
import {
	addHandleImportNotification,
	addHandleUserImportFailedNotification,
	addHandleUserImportSuccessNotification,
} from './trade-signalr.import';
import {
	addCalculationFailureNotification,
	addCalculationInitiationNotification,
	addCalculationSuccessNotification,
	addUserCalculationFailureNotification,
	addUserCalculationInitiationNotification,
	addUserCalculationSuccessNotification,
} from './trade-signalr.calculation';
import {
	addClientReferenceFailureNotification,
	addClientReferenceSuccessNotification,
	addUserClientReferenceFailureNotification,
	addUserClientReferenceSuccessNotification,
} from './trade-signalr.clientReference';
import { addSettingsSuccessNotification, addUserSettingsSuccessNotification } from './trade-signalr.settings';
import { addClientStatusWidgetNotification, addUserStatusWidgetNotification } from './trade-signalr.statusWidget';
import { addClientRulesetFailureNotification, addClientRulesetSuccessNotification, addUserClientRulesetFailureNotification, addUserClientRulesetSuccessNotification } from './trade-signalr.clientRuleset';

export let hubConnection: HubConnection;
let retryCounter: number;

export const createHubConnection = (): HubConnection => {
	hubConnection = new HubConnectionBuilder()
		.withUrl(envProperties.SignalRUrl + 'calculationhub', {
			transport: HttpTransportType.WebSockets,
			accessTokenFactory(): string | Promise<string> {
				return getAccessToken().then((result) => {
					if (result === null) {
						return '';
					}
					return result;
				});
			},
		})
		.withAutomaticReconnect()
		.configureLogging(LogLevel.Information)
		.build();
	return hubConnection;
};

const dispatchError = (dispatch: AppDispatch): void => {
	showErrorSnackbar(dispatch, 'Unable to establish connection with notification service');
};

const start = async (hubConnection: HubConnection, dispatch: AppDispatch): Promise<void> => {
	try {
		await hubConnection.start();
		hubConnection.serverTimeoutInMilliseconds = signalRServerTimeoutInMilliseconds;
		hubConnection.keepAliveIntervalInMilliseconds = signalRKeepAliveIntervalInMilliseconds;
		dispatch(
			signalRConnected({
				hubConnection,
			}),
		);
	} catch {
		setTimeout(() => {
			if (retryCounter < envProperties.retryPolicyCount) {
				start(hubConnection, dispatch);
			}
		}, signalRTimeout);
		retryCounter++;
	}
};

const setupHubCallbacks = (hubConnection: HubConnection, dispatch: AppDispatch, userManager: any): void => {
	try {
		hubConnection.onreconnected((): void => {
			dispatch(
				signalRConnected({
					hubConnection,
				}),
			);
		});

		hubConnection.onreconnecting(() => {
			dispatch(setIsSignalRConnected(false));
		});
		hubConnection.on(SignalrHubMethods.SettingsSuccessMessage, (settingsSuccessMessage: SettingsNotification) => {
			addSettingsSuccessNotification(settingsSuccessMessage, dispatch);
		});
		hubConnection.on(
			SignalrHubMethods.UserSettingsSuccessMessage,
			(settingsSuccessMessage: SettingsNotification) => {
				addUserSettingsSuccessNotification(settingsSuccessMessage, dispatch);
			},
		);
		hubConnection.on(
			SignalrHubMethods.CalculationInitiationNotification,
			(calculationInitiationMessage: CalculationInitiationMessage) =>
				addCalculationInitiationNotification(calculationInitiationMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.UserCalculationInitiationNotification,
			(calculationInitiationMessage: CalculationInitiationMessage) =>
				addUserCalculationInitiationNotification(calculationInitiationMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.CalculationSuccessNotification,
			(calculationSuccessMessage: CalculationSuccessMessage) =>
				addCalculationSuccessNotification(calculationSuccessMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.CalculationFailureNotification,
			(calculationFailureMessage: CalculationFailureMessage) =>
				addCalculationFailureNotification(calculationFailureMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.UserCalculationSuccessNotification,
			(calculationSuccessMessage: CalculationSuccessMessage) =>
				addUserCalculationSuccessNotification(calculationSuccessMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.UserCalculationFailureNotification,
			(calculationFailureMessage: CalculationFailureMessage) =>
				addUserCalculationFailureNotification(calculationFailureMessage, dispatch),
		);

		hubConnection.on(SignalrHubMethods.ImportSuccessNotification, (importMessage: ImportMessage) =>
			addHandleImportNotification(importMessage, dispatch),
		);
		hubConnection.on(SignalrHubMethods.ImportFailureNotification, (importMessage: ImportMessage) =>
			addHandleImportNotification(importMessage, dispatch),
		);
		hubConnection.on(SignalrHubMethods.UserImportSuccessNotification, (importMessage: ImportMessage) =>
			addHandleUserImportSuccessNotification(importMessage, dispatch),
		);
		hubConnection.on(SignalrHubMethods.UserImportFailureNotification, (importMessage: ImportMessage) =>
			addHandleUserImportFailedNotification(importMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.ClientReferenceSuccessNotification,
			(clientReferenceUploadMessage: ClientReferenceUploadMessage) =>
				addClientReferenceSuccessNotification(clientReferenceUploadMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.ClientReferenceFailureNotification,
			(clientReferenceUploadMessage: ClientReferenceUploadMessage) =>
				addClientReferenceFailureNotification(clientReferenceUploadMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.UserClientReferenceSuccessNotification,
			(clientReferenceUploadMessage: ClientReferenceUploadMessage) =>
				addUserClientReferenceSuccessNotification(clientReferenceUploadMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.UserClientReferenceFailureNotification,
			(clientReferenceUploadMessage: ClientReferenceUploadMessage) =>
				addUserClientReferenceFailureNotification(clientReferenceUploadMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.StatusWidgetNotification,
			(statusWidgetMessage: StatusWidgetMessage) =>
				addClientStatusWidgetNotification(statusWidgetMessage, dispatch, userManager),
		);
		hubConnection.on(
			SignalrHubMethods.StatusWidgetUserNotification,
			(statusWidgetMessage: StatusWidgetMessage) =>
				addUserStatusWidgetNotification(statusWidgetMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.ClientRulesetSuccessNotification,
			(clientReferenceUploadMessage: ClientReferenceUploadMessage) =>
				addClientRulesetSuccessNotification(clientReferenceUploadMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.ClientRulesetFailureNotification,
			(clientReferenceUploadMessage: ClientReferenceUploadMessage) =>
				addClientRulesetFailureNotification(clientReferenceUploadMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.UserClientRulesetSuccessNotification,
			(clientReferenceUploadMessage: ClientReferenceUploadMessage) =>
				addUserClientRulesetSuccessNotification(clientReferenceUploadMessage, dispatch),
		);
		hubConnection.on(
			SignalrHubMethods.UserClientRulesetFailureNotification,
			(clientReferenceUploadMessage: ClientReferenceUploadMessage) =>
				addUserClientRulesetFailureNotification(clientReferenceUploadMessage, dispatch),
		);
	} catch {
		retryCounter++;
	}
};

export const startHub = (hubConnection: HubConnection, dispatch: AppDispatch, userManager: any): void => {
	retryCounter = 0;
	setupHubCallbacks(hubConnection, dispatch, userManager);
	start(hubConnection, dispatch);
};
