import { Guid } from 'guid-typescript';
import { Fetch } from '../ApiFetch/ApiFetch';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { envProperties } from '../../core/Utils/EnvProperties';
import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';

const cemBaseUrlv3 = `${envProperties.CemBaseUrl}v3`;
const cemBaseUrlv2 = `${envProperties.CemBaseUrl}v2`;
const cemBaseUrlv1 = `${envProperties.CemBaseUrl}v1`;

export const fetchClients = async (): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv2}/clients?$filter=ApplicationId eq '${envProperties.authentication.clientId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchEngagements = async (mdmClientId: number): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv2}/engagements?$filter=MdmClientId eq '${mdmClientId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchProjects = async (mdmClientId: number): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv2}/projects?$filter=MdmClientId eq '${mdmClientId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchJobs = async (clientId: number): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv2}/jobs?$filter=mdmclientid eq '${clientId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchUserAuthorization = async (): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv3}/Authorization/User`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchJobDetailsByJobId = async (jobId: string): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv1}/Jobs?$filter=Id eq '${jobId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchProjectDetailsById = async (projectId: Guid): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv3}/projects?$filter=Ids in (${projectId})`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
}

export const fetchClientDetailsByClientId = async (clientId: number) : Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv3}/clients/${clientId}`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
}
export const fetchEntitiesDetailsByProjectId = async (projectId: Guid) : Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrlv2}/legalentities?$filter=ProjectId eq '${projectId.toString()}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
}