import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { Messages } from '../../Constants/Messages';
import { showErrorSnackbar } from '../../component/snackbar/Snackbar.slice';
import { UserAuthorization, UserAuthorizationApi } from '../Types/Authorization.types';
import { fetchUserAuthorization } from '../../api/CEM/cem.api';
import { UserRoles } from '../../Constants/UserRoles';
import { fetchFavoriteJobsData } from '../../pages/home/entity/FavoriteEntity.slice';

interface State {
    permissions: UserAuthorization;
    presenterClients: string[];
}

const initialState: State = {
    permissions: {} as UserAuthorization,
    presenterClients: [] as string[]
};

export const fetchPermissions = createAsyncThunk<
void,
void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
    >('cem/authorization', async (_: void, thunkAPI) => {
    fetchUserAuthorization().then(async function (values) {
        if (values.Success) {
            let data = JSON.parse(values.Data.toString()) as UserAuthorizationApi;
            let result: UserAuthorization = {
                application: {
                    permissions: data.application?.map(x => x.permissions).flat(2),
                    role: data.application?.map(x => x.role).flat(2),
                    features: data.application?.map(x => x.features).flat(2)
                },
                client: data.client
            }
            const presenter = data.client
                .filter(x => x.role.includes(UserRoles.Presenter)).map(
                    function (client) {
                        return client.id;
                    }
                );
            thunkAPI.dispatch(setPresenterClients(presenter))
            thunkAPI.dispatch(fetchFavoriteJobsData);
            thunkAPI.dispatch(setUserAuthorization(result));
        } else {
            showErrorSnackbar(
                thunkAPI.dispatch,
                Messages.EXCEPTION_FOR_FETCHING_AUTHORIZATION
            );
            return;
        }
    });
});

const permissionSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {
        setUserAuthorization(state, action: PayloadAction<UserAuthorization>): any {
            return { ...state, permissions: action.payload };
        },
        setPresenterClients(state, action:PayloadAction<string[]>): any {
            return { ...state, presenterClients: action.payload };
        },
        setDatasetToInitial(): State {
            return { ...initialState };
        },
    },
});

export const { setUserAuthorization, setPresenterClients, setDatasetToInitial } = permissionSlice.actions;

export default permissionSlice.reducer;