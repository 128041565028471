import React, { useEffect, useMemo, useState } from 'react';
import AuthorizationContext, { clearUserAuthorization } from '../contexts/authorizationContext';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { UserAuthorization } from '../Types/Authorization.types';
import { RootState } from '../../app/store';
import { fetchPermissions } from '../Slices/Permissions.slice';
import { clearAllCemData, fetchClientData } from '../Slices/Cem.slice';

const CemAuthorization = (props: any): React.ReactElement => {
  const dispatch = useAppDispatch();
  const fetchedClientList = useAppSelector(
    (state: RootState) => state.permission.presenterClients
  );
  const [userAuthorization, setUserAuthorization] = useState<UserAuthorization>(clearUserAuthorization);
  const userAuthorizationValue = useMemo(() => ({
    userAuthorization, setUserAuthorization
  }), [userAuthorization])

  useEffect(() => {
    dispatch(clearAllCemData());
    dispatch(fetchPermissions());
    dispatch(fetchClientData(fetchedClientList));
  }, [dispatch]);

  return (
    <AuthorizationContext.Provider value={userAuthorizationValue}>
      {props.children}
    </AuthorizationContext.Provider>
  )
};
export default CemAuthorization;
