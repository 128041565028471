import {
    CalculationSettingsFactorResponse,
    CalculationSubSettingsFactorResponse,
    GetAnalysisSubTypes
} from './Pricing.types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { getCalculationSettingsFactor } from '../../api/Pricing/CalculationSettingsFactor.api';
import { ResponseData } from '../../api/ApiFetch/ApiResponse';
import { getCalculationSubSettingsFactor } from '../../api/Pricing/CalculationSubSettingsFactor.api';
import { AnalysisGroup } from '../../component/Settings/Settings.types';

interface State {
    calculationSettingsFactor: CalculationSettingsFactorResponse[];
    calculationSubSettingsFactor: CalculationSubSettingsFactorResponse[];
}

const initialState: State = {
    calculationSettingsFactor: [] as CalculationSettingsFactorResponse[],
    calculationSubSettingsFactor: [] as CalculationSubSettingsFactorResponse[]
};
 
export const fetchCalculationSettingsFactor = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('dataset/fetchCalculationSettingsFactor', async (_: void, thunkAPI) => {
    getCalculationSettingsFactor().then(function (result: ResponseData) {
        if (result.Success) {
            const state = thunkAPI.getState();
            let data = JSON.parse(result.Data.toString()) as CalculationSettingsFactorResponse[];
            data.map(info => {
                const analysisGroup=  state.analysis.analysisDetails.analysis.find(x=> x.analysisGroup == info.analysisGroup);
                info.analysisGroupName = analysisGroup? analysisGroup.analysisName : '';
                return info;
            });
            thunkAPI.dispatch(setCalculationSettingsFactor(data));
        }
    });
});


export const fetchCalculationSubSettingsFactor = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('dataset/fetchCalculationSubSettingsFactor', async (_: void, thunkAPI) => {
    getCalculationSubSettingsFactor().then(function (result: ResponseData) {
        if (result.Success) {
            const state = thunkAPI.getState();
            let data = JSON.parse(result.Data.toString()) as CalculationSubSettingsFactorResponse[];
            data.map(info => {
                info.analysisSubTypeName = GetAnalysisSubTypes(info.analysisGroup, info.analysisSubType, state.analysis.analysisDetails.analysisSettings);
                if(info.analysisGroup == AnalysisGroup.Calculation){
                    info.analysisGroupName = 'Calculation';
                    return info;
                }
                const analysisGroup = state.analysis.analysisDetails.analysis.find(x=> x.analysisGroup == info.analysisGroup);
                info.analysisGroupName = analysisGroup? analysisGroup.analysisName : '';
                return info;
            });
            thunkAPI.dispatch(setCalculationSubSettingsFactor(data));
        }
    });
});

const pricingSlice = createSlice({
    name: 'analysis',
    initialState,
    reducers: {
        setCalculationSettingsFactor(state, action: PayloadAction<CalculationSettingsFactorResponse[]>): any {
            return { ...state, calculationSettingsFactor: action.payload };
        },
        setCalculationSubSettingsFactor(state, action: PayloadAction<CalculationSettingsFactorResponse[]>): any {
            return { ...state, calculationSubSettingsFactor: action.payload };
        },
        setPricingToInitial(): State {
            return { ...initialState };
        },
    },
});

export const { setPricingToInitial, setCalculationSubSettingsFactor,setCalculationSettingsFactor  } = pricingSlice.actions;

export default pricingSlice.reducer;
