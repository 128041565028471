import { useContext, useEffect } from 'react';
import { batch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchSettingsData } from '../../pages/input/inputDetails/InputDetails.slice';
import { createHubConnection, hubConnection, startHub } from '../signalr/trade-signalr';
import {
	clientSelected, jobSelected, projectSelected
} from '../Slices/Cem.slice';
import { fetchDatasetDetails } from '../Slices/Dataset.slice';
import { joinGroup } from '../Slices/Notifications.slice';
import { pageNotFoundPath } from '../Utils/constants';
import DeepLinkingContext from './DeepLinkingContext';
import { OidcIdentityContext } from '@rsmus/react-auth';
import { fetchJobDetailsByJobId, fetchProjectDetailsById } from '../../api/CEM/cem.api';
import { Job } from '../Types/Job.types';
import { Project } from '../Types/Project.types';
import { Client } from '../Types/Client.types';
import { fetchUserListData } from '../../component/status-widget/statusWidget.slice';
import { RootState } from '../../app/store';

const DeepLinkingContextProvider = ({ children }: any) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const cemDataLoaded = useAppSelector((state: RootState) => state.cem.dataLoaded);
	const clients = useAppSelector((state: RootState) => state.cem.clients);
	const projects = useAppSelector((state: RootState) => state.cem.projects);
	const jobs = useAppSelector((state: RootState) => state.cem.jobs);
	const userManager = useContext(OidcIdentityContext);

	const handleDeepLinkingOrRedirectHome = async (jobId: string) => {
		if (jobId) {
			if (cemDataLoaded) {
				const job = jobs.find(j => j.id.toString() === jobId);
				if (job === undefined) {
					history.replace(pageNotFoundPath);
				}
				const project = projects.find(x => x.id === job!.projectId);
				if (project === undefined) {
					history.replace(pageNotFoundPath);
				}
				const client = clients.find(x => x.mdmClientId === project?.mdmClientId)
				if (client === undefined)
					history.replace(pageNotFoundPath);

				bulkDispatch(job!, project!, client!);
				return;
			}
			else {
				const jobDetails = await fetchJobDetailsByJobId(jobId);
				if (jobDetails.Success) {
					const job = JSON.parse(jobDetails.Data.toString()).results[0] as Job;
					dispatch(jobSelected(job));
					if (!handleDeepLinkingURL(job)) {
						history.replace(pageNotFoundPath);
					}
					return;
				}
				history.replace(pageNotFoundPath);
			}
		}
	};

	useEffect(() => {
		if (!hubConnection) {
			const hubConnection = createHubConnection();
			startHub(hubConnection, dispatch, userManager);
		}
	}, [dispatch]);

	const handleDeepLinkingURL = (job: Job): boolean => {
		if (!cemDataLoaded) {
			fetchProjectDetailsById(job.projectId)
				.then(async function (result) {
					if (result.Success) {
						const project = JSON.parse(result.Data.toString()).results[0] as Project;
						if (project) {
							dispatch(projectSelected(project));
							let client = clients.find(c => c.mdmClientId === project.mdmClientId);
							if (!client)
								return false;
							bulkDispatch(job, project, client)
						}
						else {
							return false;
						}
					}
				});
			return true;
		}
		return true;
	}

	const bulkDispatch = (job: Job, project: Project, client: Client) => {
		batch(() => {
			dispatch(jobSelected(job));
			dispatch(projectSelected(project!));
			dispatch(clientSelected(client));
			dispatch(fetchUserListData(job!.id));
			dispatch(joinGroup({ hubConnection: hubConnection, groupId: job!.id.toString() }));
			dispatch(joinGroup({ hubConnection: hubConnection, groupId: client!.mdmClientId.toString() }));
			dispatch(fetchDatasetDetails(job!.id));
			dispatch(fetchSettingsData(job!.id));
		});
	}

	return (
		<DeepLinkingContext.Provider value={{ handleDeepLinkingOrRedirectHome }}>
			{children}
		</DeepLinkingContext.Provider>
	);
};

export default DeepLinkingContextProvider;
