import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';
import { ApiUrls } from '../ApiConstants/ApiUrls';
import { FetchApi } from '../ApiFetch/ApiFetch';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';

export const getCalculationSettingsFactor = async (): Promise<ResponseData> => {
    try {
        const url = ApiUrls.GET_CALCULATION_SETTINGS_FACTOR;
        const response = await FetchApi({
            endpoint: url,
            apiType: ServiceType.Calculation,
            method: HTTPMethod.Get,
            apiVersion: 2,
        });
        return CreateTextResponse(response);
    } catch (e) {
        throw e;
    }
};

export const createCalculationSettingsFactor = async (data: any): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.CREATE_CALCULATION_SETTINGS_FACTOR;
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Post,
        headers: {
            'Content-Type': 'application/json'
        },
        apiType: ServiceType.Calculation,
        body: JSON.stringify(data),
        apiVersion: 2,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const updateCalculationSettingsFactor = async (data: any): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.UPDATE_CALCULATION_SETTINGS_FACTOR.replace('{id}', data.id);
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Post,
        headers: {
            'Content-Type': 'application/json'
        },
        apiType: ServiceType.Calculation,
        body: JSON.stringify(data),
        apiVersion: 2,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const deleteCalculationSettingsFactor = async (id: any): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.DELETE_CALCULATION_SETTINGS_FACTOR.replace('{id}', id);
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Delete,
        headers: {
            'Content-Type': 'application/json'
        },
        apiType: ServiceType.Calculation,
        apiVersion: 2,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};
