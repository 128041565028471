import { Guid } from 'guid-typescript';

export interface SettingsProps {
	visible: boolean;
	onClose: () => void;
}

export interface Setting {
	CalculationTypeId: string;
	Name: string;
	IsActive: boolean;
	SubSetting: SubSetting[];
	ShowSubSetting: boolean;
	IsDividend: boolean;
	Disable: boolean;
}

export interface AnalysisSettingsContext {
	setJobSettings: (jobId: Guid) => void;
	isWashSalesOverlappingDays: boolean;
	setIsWashSalesOverlappingDays: React.Dispatch<React.SetStateAction<boolean>>;
	analysisSettingsValue: string[];
	setAnalysisSettingsValue: React.Dispatch<React.SetStateAction<string[]>>;
	isWashSalesEnabled: boolean;
	setIsWashSalesEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	isPaymentEnabled: boolean;
	setIsPaymentEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	isStraddlesEnabled: boolean;
	setIsStraddlesEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	showDividendOptions: boolean;
	setShowDividendOptions: React.Dispatch<React.SetStateAction<boolean>>;
	overrideDividendFactors: boolean;
	setOverrideDividendFactors: React.Dispatch<React.SetStateAction<boolean>>;
	clientType: string;
	setClientType: React.Dispatch<React.SetStateAction<string>>;
	bifurcation: string;
	setBifurcation: React.Dispatch<React.SetStateAction<string>>;
	periodStartDate: Date;
	setPeriodStartDate: React.Dispatch<React.SetStateAction<Date>>;
	periodEndDate: Date;
	setPeriodEndDate: React.Dispatch<React.SetStateAction<Date>>;
	isDefaultSetting: boolean;
	setIsDefaultSetting: React.Dispatch<React.SetStateAction<boolean>>;
	isCharacterChecked: boolean;
	setIsCharacterChecked: React.Dispatch<React.SetStateAction<boolean>>;
	isIRC475Enabled: boolean;
	setIsIRC475Enabled: React.Dispatch<React.SetStateAction<boolean>>;
	isForcedConstructiveSales: string;
	setIsForcedConstructiveSales: React.Dispatch<React.SetStateAction<string>>;
	constructiveSalesChecked: boolean;
	setConstructiveSalesChecked: React.Dispatch<React.SetStateAction<boolean>>;
	isHedgingChecked: boolean;
	setIsHedgingChecked: React.Dispatch<React.SetStateAction<boolean>>;
	isOneToOneSelected: boolean;
	setIsOneToOneSelected: React.Dispatch<React.SetStateAction<boolean>>;
	characterRadioCount: string;
	setCharacterRadioCount: React.Dispatch<React.SetStateAction<string>>;
	ircRadioCount: string;
	setIRCRadioCount: React.Dispatch<React.SetStateAction<string>>;
	reliefMethod: string;
	setReliefMethod: React.Dispatch<React.SetStateAction<string>>;
	hedgingRadioCount: string;
	setHedgingRadioCount: React.Dispatch<React.SetStateAction<string>>;
	reportingRadioCount: string;
	setReportingRadioCount: React.Dispatch<React.SetStateAction<string>>;
	realizedReliefMethod: string;
	setRealizedReliefMethod: React.Dispatch<React.SetStateAction<string>>;
	offsetReliefMethod: string;
	setOffsetReliefMethod: React.Dispatch<React.SetStateAction<string>>;
	accrualBasis: string;
	setAccrualBasis: React.Dispatch<React.SetStateAction<string>>;
	isMTMEnabled: boolean;
	setIsMTMEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	mtmSubSettings: MTMSubSetting[],
	setMTMSubSettings: React.Dispatch<React.SetStateAction<MTMSubSetting[]>>;
	isSameDaySelected: boolean;
	setIsSameDaySelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface MTMSubSetting {
	name?: number;
	value: boolean;
};

export interface SubSetting {
	Name: string;
	IsActive?: boolean;
}

export interface Warehouse {
	Type?: number;
	WarehousesId: Guid;
	EnrichmentMode: number;
}

export interface Library {
	AssetMasterWarehouse?: Warehouse;
	DividendWarehouse?: Warehouse;
	Rulesets?: Guid;
	ClientReferenceLibrary?: Guid;
}

export interface LibrarySet {
	AssetMasterWarehousesId: string;
}

export enum EnrichmentMode {
	FillBlanks = 0,
	OverwriteAll = 1,
	OverwriteAndIgnoreWarehouseBlanks = 2,
}

export enum DataTypes {
	Boolean = 'Boolean',
	Date = 'Date',
	Number = 'Number',
	String = 'String',
}

export enum WarehouseType {
	RsmWarehouse,
	ClientWarehouse,
}

export interface LibraryRetrived {
	Name: string;
	IsActive: boolean;
	Id: Guid;
	Type: WarehouseType;
	FileUploadId: Guid;
	ImportStatus: number;
}

export enum LibraryType {
	ClientReferenceLibrary,
	Rulesets,
	AssetMasterWarehouses,
	DividendWarehouses,
}

export enum ClientType {
	Investor = 'Investor',
	Trader = 'Trader',
}

export enum Bifurcation {
	Upload = 'Upload',
	Disposition = 'Disposition',
}

export enum ReliefMethodOptions {
	FIFO = 'FIFO',
	LIFO = 'LIFO',
	MIN_GAIN = 'MIN_GAIN',
	MAX_GAIN = 'MAX_GAIN',
}

export interface RetriveEnrichmentSettings {
	LibraryType: LibraryType;
	LibraryValue: string;
}

export interface AnalysisSetting {
	analysisSettingID: string;
	name: string;
	displayOnUi?: boolean;
	analysisGroup: AnalysisGroup;
}

export interface WarehouseData {
	Type: number;
	WarehousesId: Guid;
	EnrichmentMode: number;
}

export interface Analysis {
	analysisID: Guid;
	analysisName: string;
	isSupported: boolean;
	analysisGroup: AnalysisGroup;
}

export interface AnalysisSettingsResponse {
	analysis: Analysis[];
	analysisSettings: AnalysisSetting[];
}

export interface AnalysisSettingsValue {
	settingValueType: string;
	settingValues: string[];
	analysisSettingId: string;
	type?: Hedging | ConstructiveSales | AnalysisGroup | CalculationBaseGroup | Dividend | MTM;
}

export interface AnalysisSettingResponse {
	analysisSettingId: string;
	analysisSettingName: string;
	settingValue: string;
}

export interface AnalysisResponse {
	analysisId: string;
	analysisName: string;
	analysisSettingId: string;
	analysisSettingResponse: AnalysisSettingResponse[];
}

export interface AnalysisSettingByJob {
	settingValue: string;
	analysisGroup: AnalysisGroup;
}

export interface AnalysisGroupValue {
	analysis?: string[];
	analysisSetting: AnalysisSettingsValue[];
}

export enum AnalysisGroup {
	Calculation = 0,
	WashSales = 1,
	Dividend = 2,
	Payments = 3,
	Character = 4,
	Straddles = 5,
	ConstructiveSales = 6,
	Hedging = 7,
	IRC475 = 8,
	MTM = 9
}

export enum CalculationBaseGroup {
	PeriodTo = 3,
	PeriodFrom = 4,
	ClientType = 5,
	Reporting = 6,
	Bifurcation=7
}

export enum Reporting {
	Daily = 'Daily',
	Monthly = 'Monthly',
	Annual = 'Annual',
}

export enum Hedging {
	HedgingType = 1,
	OffsetRelief = 2,
	RealizedRelief = 3,
	SameDayHedging = 4
}

export enum MTM {
	_1256 = 1,
	_475f1 = 2,
	_475f2 = 3,
	SWAP = 4,
	PFIC = 5
}

export enum ConstructiveSales {
	ReliefMethodology = 1,
	ForcedConstructiveSales = 2,
}

export enum HedgingType {
	OneToAll = 'OneToAll',
	OneToOne = 'OneToOne',
}

export enum AccrualBasis {
	Cash = 'Cash',
	Accrual = 'Accrual'
}

export enum Character {
	SixtyFortySplit = 'SixtyFortySplit',
	IRC1256 = 'IRC1256',
}

export enum ForcedConstructiveSales {
	Yes = 'Yes',
	No = 'No',
}

export enum IRC475 {
	FourSevenFiveF1 = '475f1',
	FourSevenFiveF2 = '475f2',
}

export interface AnalysisSettingName {
	name: string;
	type: Hedging | ConstructiveSales | Dividend | MTM;
}

export enum Dividend {
	OverridDividendFactors = 1,
	AccrualBasis = 2
}

export const MTMSubSettingDefault: MTMSubSetting[] = [
		{name:MTM._1256, value: true},{name:MTM._475f1, value: false},
		{name:MTM._475f2, value: false},{name:MTM.SWAP, value: false},
		{name:MTM.PFIC, value: false}
	];
