import { Guid } from 'guid-typescript';
import { ApiUrls } from '../ApiConstants/ApiUrls';
import { FetchApi } from '../ApiFetch/ApiFetch';
import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { ServiceType } from '../ApiFetch/ServiceType.types';

export const fetchUserList = async (jobId: Guid): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.FETCH_USER_LIST_FOR_JOB.replace('{jobId}', jobId.toString());
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Get,
        apiType: ServiceType.StatusWidget,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};