import { FetchApi } from '../ApiFetch/ApiFetch';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { Guid } from 'guid-typescript';
import { InputFileRequest } from '../../core/Types/InputFileRequest.types';
import { CreateBlobResponse, CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';
import { ApiUrls } from '../ApiConstants/ApiUrls';
import StatusCode from 'status-code-enum';

export const fetchInputByType = async (inputFileRequest: InputFileRequest): Promise<ResponseData> => {
	if (inputFileRequest.datasetId.toString() === Guid.EMPTY || !inputFileRequest.jobId || !inputFileRequest.type) {
		return CreateTextResponse({} as Response);
	}
	const response = await FetchApi({
		endpoint: ApiUrls.GET_DATASET_INPUT_JSON.replace('{jobId}', inputFileRequest.jobId.toString())
			.replace('{datasetId}', inputFileRequest.datasetId.toString())
			.replace('{type}', inputFileRequest.type.toString()),
		apiType: ServiceType.Import,
		method: HTTPMethod.Get,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const fetchLibrariesByType = async (inputFileRequest: InputFileRequest): Promise<ResponseData> => {
	if (!inputFileRequest.jobId || !inputFileRequest.type) {
		return CreateTextResponse({} as Response);
	}
	const response = await FetchApi({
		endpoint: ApiUrls.GET_LIBRARY_INPUT_JSON.replace('{id}', inputFileRequest.settingsId!).replace(
			'{type}',
			inputFileRequest.type.toString(),
		).replace('{jobId}', inputFileRequest.jobId.toString()),
		apiType: ServiceType.Enrichment,
		method: HTTPMethod.Get,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const downloadInput = async (importId: string, jobId: string): Promise<ResponseData> => {
	const response = await FetchApi({
		endpoint: ApiUrls.GET_CALCULATION_INPUT.replace('{importId}', importId).replace('{jobId}', jobId),
		apiType: ServiceType.Import,
		method: HTTPMethod.Get,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
};

export const fetchActiveDatasetDetails = async (jobId: Guid): Promise<ResponseData> => {
	const response = await FetchApi({
		endpoint: 'job/' + jobId + '/active-dataset/',
		apiType: ServiceType.Import,
		method: HTTPMethod.Get,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};
