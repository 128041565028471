import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Snackbar } from './component/snackbar/Snackbar';
import DeepLinkingContextProvider from './core/contexts/DeepLinkingContextProvider';
import AnalysisContextProvider from './core/contexts/AnalysisContextProvider';
import { Authenticated, getAccessToken, OidcIdentity } from '@rsmus/react-auth';
import { envProperties } from './core/Utils/EnvProperties';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/icons/dxiconsmaterial.woff2';
import 'devextreme/dist/css/icons/dxiconsmaterial.woff';
import 'devextreme/dist/css/icons/dxiconsmaterial.ttf';
import './pages/dashboard/Dashboard.css'
import './core/CommonStyles/forms.css'
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import {
	pageNotFoundAltText,
	pageNotFoundHeader,
	pageNotFoundText,
	unauthorizedAltText,
	unauthorizedHeader,
	unauthorizedText
} from './pages/ErrorPage/ErrorPage.constants';
import pageNotFoundImage from './assets/images/icon-page-not-found.svg';
import unauthorizedImage from './assets/images/icon-unauthorized.svg';
import { createTheme, LinearProgress, MuiThemeProvider } from '@material-ui/core';
import { ApplicationTheme, setPrimaryColor } from './ApplicationTheme.Utils';
import CemAuthorization from './core/components/cemAuthorization';
import IdleTimeLogout from './component/LogOut/IdleTimeLogout';
import './App.css';
import { Eula } from '@rsmus/rsm-eula';

const Home = lazy(() => import('./pages/home/Home'));
const UsageStatistics = lazy(() => import('./pages/UsageStatistics/UsageStatistics'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/ErrorPage'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const BookToTaxOutput = lazy(() => import('./pages/outputs/bookToTaxOutput/BookToTaxOutput'));
const CombinedOutput = lazy(() => import('./pages/outputs/combinedOutput/CombinedOutput'));
const DividendOutput = lazy(() => import('./pages/outputs/dividendOutput/DividendOutput'));
const StraddleOutput = lazy(() => import('./pages/outputs/straddleOutput/StraddleOutput'));
const OneToOneOutput = lazy(() => import('./pages/outputs/oneToOne/OneToOne'));
const AssetOfInterestOutput = lazy(() => import('./pages/outputs/assetsOfInterest/AssetOfInterestOutput'));
const AssetOfInterest = lazy(() => import('./pages/admin/assetsOfInterest/AssetOfInterest'));
const Settings = lazy(() => import('./component/Settings/Settings'));
const InputMaster = lazy(() => import('./pages/input/InputMaster'));
const ReferenceTable = lazy(() => import('./pages/admin/Lists/ReferenceTable/ReferenceTable'));
const Rulesets = lazy(() => import('./pages/admin/Lists/Rulesets/Rulesets'));
const AssetWarehouse = lazy(() => import('./pages/admin/Lists/AssetWarehouse/AssetWarehouse'));
const DividendWarehouse = lazy(() => import('./pages/admin/Lists/Dividend/DividendWarehouse'));
const DataTemplates = lazy(() => import('./pages/admin/Lists/DataTemplates/DataTemplates'));
const PricingSettings = lazy(() => import('./pages/Pricing/PricingSettings'));

const App = (): React.ReactElement => {
	const theme = createTheme({
		typography: {
			h1: {
				fontSize: '14px',
				fontWeight: 500,
				lineHeight: 1.2,
				color: '#2d2d2d',
				textTransform: 'uppercase',
			},
			h2: {
				fontSize: '16px',
				fontWeight: 500,
				lineHeight: 1.2,
				color: '#2d2d2d',
			},
			body1: { color: '#2d2d2d', fontSize: '13px' },
		},
		palette: {
			primary: {
				main: setPrimaryColor(ApplicationTheme.Blue),
				contrastText: '#fff',
			},
		},
		props: { MuiPaper: { square: true } },
	});

	useEffect(() => {
		const handleContextmenu = (e: any) => {
			e.preventDefault();
			e.stopPropagation();
		};
		const handleCtrlClick = (e: any) => {
			if (e.button === 0 && e.ctrlKey || e.shiftKey) {
				e.preventDefault();
				e.stopPropagation();
			}
		};
		document.addEventListener('click', handleCtrlClick);
		document.addEventListener('contextmenu', handleContextmenu);
	}, []);

	const myhistory = useHistory();

	const getToken = async (): Promise<string> => {
		const token = await getAccessToken()
		return token || ''
	}

	const handleRedirectCallback = (url: string) => {
		try {
			const fullUrl = new URL(url)
			if (fullUrl.pathname !== myhistory.location.pathname) {
				myhistory.push(fullUrl.pathname)
			} else {
				window.history.replaceState({}, '', url)
			}
		} catch (error) {
			throw error;
		}
	}

	return (
		<div>
			<Snackbar />
			<MuiThemeProvider theme={theme}>
				<OidcIdentity
					authority={envProperties.authentication.authority}
					redirect_uri={window.location.origin}
					client_id={envProperties.authentication.clientId}
					onDeepLinkRedirectCallback={handleRedirectCallback}
					redirectAfterLogout={true}
					postLogoutRedirectUri={'https://rsmus.com'}
					accessTokenExpiringNotificationTimeInSeconds={120}
					automaticSilentRenew={true}
					silentRequestTimeoutInSeconds={60}
				>
					<Authenticated>
						<Eula host={envProperties.EulaApiUrl} tokenFactory={getToken}>
							<CemAuthorization>
								<IdleTimeLogout />
								<DeepLinkingContextProvider>
									<AnalysisContextProvider>
										<Suspense fallback={<LinearProgress />}>
											<Switch>
												<Route exact path={['/job/:jobId/dashboard', '/dashboard']} render={() => <Dashboard />} />
												<Route path='/job/:jobId/inputs' render={() => <InputMaster />} />
												<Route exact path='/job/:jobId/outputs/assets-of-interest' render={() => <AssetOfInterestOutput />} />
												<Route exact path='/job/:jobId/outputs/book-to-tax' render={() => <BookToTaxOutput />} />
												<Route exact path='/job/:jobId/outputs/combined' render={() => <CombinedOutput />} />
												<Route exact path='/job/:jobId/outputs/dividend' render={() => <DividendOutput />} />
												<Route exact path='/job/:jobId/outputs/straddles' render={() => <StraddleOutput />} />
												<Route exact path='/job/:jobId/outputs/onetoone' render={() => <OneToOneOutput />} />
												<Route exact path={['/job/:jobId/settings', '/settings']} render={() => <Settings visible={false} onClose={function (): void { }} />} />
												<Route path={['/job/:jobId/admin/assets-of-interest', '/admin/assets-of-interest']} render={() => <AssetOfInterest />} />
												<Route exact path={['/job/:jobId/admin/reference-tables', '/admin/reference-tables']} render={() => <ReferenceTable />} />
												<Route exact path={['/job/:jobId/admin/rulesets', '/admin/rulesets']} render={() => <Rulesets />} />
												<Route exact path='/admin/empty-data-templates' render={() => <DataTemplates />} />
												<Route exact path='/job/:jobId/admin/asset-warehouse' render={() => <AssetWarehouse />} />
												<Route exact path='/job/:jobId/admin/dividend-warehouse' render={() => <DividendWarehouse />} />
												<Route exact path='/page-not-found' render={() => <ErrorPage image={pageNotFoundImage} text={pageNotFoundText} header={pageNotFoundHeader} altText={pageNotFoundAltText} />} />
												<Route exact path='/unauthorized' render={() => <ErrorPage image={unauthorizedImage} text={unauthorizedText} header={unauthorizedHeader} altText={unauthorizedAltText} />} />
												<Route exact path="/home" render={() => <Home />} />
												<Route exact path="/usage-statistics" render={() => <UsageStatistics />} />
												<Route exact path="/pricing" render={() => <PricingSettings />} />
												<Route path="*" render={() => <Home />} />
											</Switch>
										</Suspense>
									</AnalysisContextProvider>
								</DeepLinkingContextProvider>
							</CemAuthorization>
						</Eula>
					</Authenticated>
				</OidcIdentity>
			</MuiThemeProvider>
		</div>
	);
};
export default App;
